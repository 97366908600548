/* ----------------------------------------------------------------------------
    CSS Variable, needs to be set in :root
----------------------------------------------------------------------------- */
:root {
  --font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
/* ----------------------------------------------------------------------------
    Smooth Scrolling
----------------------------------------------------------------------------- */
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
[id] {
  scroll-margin-top: 1rem;
}
/* ----------------------------------------------------------------------------
    Typography
----------------------------------------------------------------------------- */
body {
  line-height: 1.6;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
  font-family: var(--font-family);
  line-height: 1.4;
  color: #555;
}
h1,
.h1 {
  font-size: 1.602em;
}
h2,
.h2 {
  font-size: 1.424em;
}
h3,
.h3 {
  font-size: 1.266em;
}
h4,
.h4 {
  font-size: 1.125em;
}
h5,
.h5 {
  font-size: 1em;
}
@media (min-width: 48em) {
  /* Medium devices (tablets, 768px and up) */
  h1,
  .h1 {
    font-size: 1.8em;
  }
  h2,
  .h2 {
    font-size: 1.55em;
  }
  h3,
  .h3 {
    font-size: 1.32em;
  }
  h4,
  .h4 {
    font-size: 1.125em;
  }
  h5,
  .h5 {
    font-size: 1em;
  }
}
@media (min-width: 62em) {
  /* Large devices (desktops, 992px and up) */
  h1,
  .h1 {
    font-size: 2.074em;
  }
  h2,
  .h2 {
    font-size: 1.728em;
  }
  h3,
  .h3 {
    font-size: 1.44em;
  }
  h4,
  .h4 {
    font-size: 1.2em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
@media (min-width: 75em) {
  /* Extra large devices (large desktops, 1200px and up) */
  h1,
  .h1 {
    font-size: 2.441em;
  }
  h2,
  .h2 {
    font-size: 1.953em;
  }
  h3,
  .h3 {
    font-size: 1.563em;
  }
  h4,
  .h4 {
    font-size: 1.25em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
a {
  color: $link-color;
  text-decoration: none;
  outline: none !important;
}
a:hover,
a:active {
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: darken($link-color, 10%);
}
a.img:hover,
a.img:active {
  text-decoration: none;
  border: 0;
}
a[href^='tel:'] {
  color: inherit;
  border: 0;
}
ul.nav,
ol.nav {
  margin-left: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}
.cfix:after,
.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

#content,
#main_content,
.container,
.container-fluid,
.break_word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* ----------------------------------------------------------------------------
    Forms Elements
----------------------------------------------------------------------------- */
.opacity-1 {
  opacity: 1 !important;
}
.display-none {
  display: none !important;
}
.invalid {
  border-color: #e74a3b;
  border-style: solid;
}
.invalid-msg {
  color: #e74a3b;
}
/* ----------------------------------------------------------------------------
    Buttons
----------------------------------------------------------------------------- */
input[type='submit'],
input[type='button'],
button {
  max-width: 220px;
  background: #f7f7f7;
  background-image: linear-gradient(#f7f7f7, #ebebeb);
  box-shadow: inset 0 0 3px #fff;
  color: #666 !important;
  &:hover {
    background-image: linear-gradient(#ebebeb, #f7f7f7);
  }
}

/* ----------------------------------------------------------------------------
    Tables
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Modals
----------------------------------------------------------------------------- */
// body.modal-open {
//   overflow: auto !important;
//   padding: 0 !important;
// }
.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  .close {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    margin-top: -0.7rem;
  }
}
.modal-body {
  .btn-close {
    opacity: 1;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    text-align: center;
    z-index: 100;
    background-color: #ffffff;
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
}
/* ----------------------------------------------------------------------------
  Dropdown List
----------------------------------------------------------------------------- */
.dropdown-list {
  margin: auto;
  width: auto;
  position: relative;
  box-sizing: border-box;
}
.dropdown-list ul.drop {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dropdown-list li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dropdown-list span.dropdown {
  display: block;
  margin: 0;
  padding: 0 10px;
  width: auto;
  height: 40px;
  line-height: 38px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #a1c4e5;
  cursor: pointer;
  overflow: hidden;
}
.dropdown-list span.dropdown.is-active {
  border-radius: 3px 3px 0 0;
}
.dropdown-list span.dropdown:hover,
.dropdown-list span.dropdown:active {
  background: #f1f1f1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.dropdown-list span.dropdown:after {
  float: right;
  margin-top: 0;
  margin-left: 10px;
  width: 15px;
  height: 100%;
  content: '\e817';
  color: ineherit;
  font-family: 'icons';
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
.dropdown-list span.dropdown.is-active:after {
  content: '\e816';
}
.dropdown-list a:hover {
  text-decoration: none;
  border-bottom: 0;
}
.dropdown-list .drop {
  display: block;
  float: left;
  margin: 0;
  width: 100%;
  max-height: 0;
  position: absolute;
  background: #fff;
  top: 40px;
  border-radius: 0 0 3px 3px;
  z-index: 10;
  overflow-y: auto;
  opacity: 0;
  transition:
    max-height 0.3s cubic-bezier(0, 0.6, 0, 1),
    opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
}
.dropdown-list span.is-active + .drop {
  max-height: 350px;
  border: 1px solid #a1c4e5;
  border-top: 0;
  opacity: 1;
  transition:
    max-height 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
}
.dropdown-list .drop li {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  height: auto;
  border-top: 1px dotted #ccc;
}
.dropdown-list .drop li:first-child {
  border-top: 0;
}
.dropdown-list .drop li a {
  float: none;
  display: block;
  margin: 0;
  padding: 6px 10px 6px 25px;
  height: auto;
  width: auto;
  text-transform: none;
  color: inherit;
  background: #fff;
  text-align: left;
  border-radius: 0;
  border: 0;
}
.dropdown-list .drop li a:hover,
.dropdown-list .drop li a:active {
  background: #f1f1f1;
}
.dropdown-list .drop li a:before {
  display: inline-block;
  margin-left: -15px;
  margin-right: 5px;
  width: 10px;
  height: inherit;
  content: '\00BB';
  color: inherit;
  font-family: 'icons';
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}

ul,
ol {
  padding-left: 30px;
}
ul li,
ol li {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}
ul.check,
ul.star,
ul.carrot {
  margin-left: 0;
  padding-left: 0;
  list-style: none outside;
  line-height: inherit;
}
ul.check li,
ul.star li,
ul.carrot li {
  padding-left: 1.3em;
  margin-left: 0;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  position: relative;
}
ul.check li li,
ul.star li li,
ul.carrot li li {
  padding-left: 0;
}
ul.carrot li {
  padding-left: 0.9em;
}
ul.check > li:before,
ul.star > li:before,
ul.carrot > li:before {
  display: block;
  margin-top: 0.1em;
  width: 1em;
  height: inherit;
  color: inherit;
  font-size: 0.9em;
  font-weight: normal !important;
  word-wrap: normal;
  position: absolute;
  left: 0;
}
ul.check > li:before {
  content: '\e840';
  font-family: 'icons';
}
ul.star > li:before {
  content: '\e834';
  font-family: 'icons';
}
ul.carrot > li:before {
  content: '\00BB';
  font-family: 'icons';
  margin-top: 0.2em;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  width: 1em;
}
/* ----------------------------------------------------------------------------
  Accordion
----------------------------------------------------------------------------- */
.accordion-wrap {
  margin-bottom: 1rem;
  .accordion {
    padding: 0;
    border-bottom: 1px dotted #ccc;
    overflow: hidden;
    &:first-child {
      border-top: 1px dotted #ccc;
    }
    .accordion-toggle {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 10px;
      padding-right: calc(1em + 10px);
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;
      // color: inherit;
      overflow: hidden; // for some reason adding this fixes an issue with the icon display
      &:before {
        float: right;
        color: inherit;
        content: '\e817';
        font-family: 'icons';
        font-size: 1em;
        font-weight: normal !important;
        height: inherit;
        width: 1em;
        margin-right: -1em;
        text-align: right;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.02);
      }
    }
    &.is-open > .accordion-content {
      padding-top: 5px;
      opacity: 1;
      transform: scaleY(1);
    }
    &.is-open > .accordion-toggle {
      &:before {
        content: '\e816';
      }
    }
    .accordion-content {
      padding: 0 10px;
      max-height: 0;
      opacity: 0;
      // transform: scaleY(0);
      transform-origin: top;
      // transition: transform 0.25s cubic-bezier(0, 0.6, 0, 1), max-height 0.25s cubic-bezier(0, 0.6, 0, 1),
      //   opacity 0.25s cubic-bezier(0, 0.6, 0, 1);
      transition:
        max-height 0.35s cubic-bezier(0.075, 0.82, 0.165, 1),
        opacity 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
      overflow: hidden;
    }
  }
}

/* ----------------------------------------------------------------------------
  Bootstrap Overrides
----------------------------------------------------------------------------- */
// .form-control:focus,
// .custom-select:focus {
//   box-shadow: none;
// }
b,
strong {
  font-weight: 600;
}
.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    font-weight: 400;
    // color: #333333;
  }
}
input[type='number'] {
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  color: #e0e0e0 !important;
}
.custom-control {
  &.is-invalid,
  &:invalid {
    .custom-control-input ~ .custom-control-label {
      color: $red;
    }
    .custom-control-input ~ .custom-control-label:before {
      border-color: $red;
    }
    .custom-control-input:focus ~ .custom-control-label:before {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
  &.custom-checkbox {
    padding-left: 1.75rem;
    .custom-control-label {
      &:before,
      &:after {
        left: -1.75rem;
        width: 1.2rem;
        height: 1.2rem;
      }
    }
    .custom-control-input:checked ~ .custom-control-label {
      &:before {
        border-color: $blue;
        background-color: $blue;
      }
    }
  }
  &.custom-radio {
    padding-left: 1.75rem;
    .custom-control-label {
      &:before,
      &:after {
        left: -1.75rem;
        width: 1.2rem;
        height: 1.2rem;
      }
    }
    .custom-control-input:checked ~ .custom-control-label {
      &:before {
        border-color: $blue;
        background-color: $blue;
      }
    }
  }
}
.form-control {
  &.is-invalid {
    border-color: $red;
  }
  &:invalid:focus,
  &.is-invalid:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    background-image: none;
  }
}
.custom-select {
  &.is-invalid {
    border-color: $red;
  }
  &:invalid:focus,
  &.is-invalid:focus {
    border-color: #80bdff;
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
  }
}
.table {
  word-wrap: normal;
  word-break: normal;
  &.align-middle {
    th,
    td {
      vertical-align: middle;
    }
  }
  &.table-sm {
    td,
    th {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }
}
.list-group-item {
  margin-top: 0;
  margin-bottom: 0;
}
// Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) {
//   .container,
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container-xl {
//     max-width: 1350px;
//   }
// }

/* ----------------------------------------------------------------------------
  ASC Default stuff
----------------------------------------------------------------------------- */
.price {
  display: inline-block;
  // margin: 10px;
  padding: 0;
  width: auto;
  height: auto;
  color: #555;
  text-align: center;
  font:
    bold 0 'Trebuchet MS',
    'Droid Serif',
    Arial,
    sans-serif;
  overflow: visible;
  vertical-align: middle;
}
.price.static {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
}
.price.shown {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
  transition:
    color ease-in-out 0.1s,
    text-shadow ease-in-out 0.1s;
}
.price.ribbon {
  margin-top: -6px;
  margin-bottom: 0;
  padding: 28px 0 0 0;
  width: 140px;
  height: 93px;
  color: #fff;
  background: url(../images/price_bg.png) no-repeat 0 0;
}
.price.burst {
  padding: 60px 0 0 0;
  width: 155px;
  height: 155px;
  color: #fff;
  background: url(../images/price_bg_burst.png) no-repeat 50% 50%;
}
.price.ribbon.static,
.price.burst.static {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}
.price.ribbon.shown,
.price.burst.shown {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  transition:
    color ease-in-out 0.1s,
    text-shadow ease-in-out 0.1s;
}
.price span.dollar-sign,
.price span.cents,
.price span.cents-fees,
.price span.only {
  display: inline-block;
  margin: 5px 0 0 0;
  font-size: 30px;
  line-height: 30px;
  vertical-align: top;
}
.price span.only {
  margin-bottom: 5px;
  font-size: 26px;
  display: block;
}
.price span.dollars,
.price span.dollars-fees {
  display: inline-block;
  margin-left: -1px;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -1px;
  vertical-align: middle;
}
.price span.cents,
.price span.cents-fees {
  margin: 4px 0 0 3px;
  letter-spacing: -1px;
}
.price.shown span.reg-price {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  text-decoration: line-through;
  padding-left: 3px;
  vertical-align: middle;
  text-shadow: none;
}
.price.ribbon span.reg-price {
  color: #eee;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}
.price span.dollar-sign {
  filter: blur(10px);
}
.price.shown span.dollar-sign {
  filter: blur(0);
}
.price span.dollars:after,
.price span.dollars-fees:after,
.price span.cents:after,
.price span.cents-fees:after,
.price span.dollars:after,
.price span.cents:after {
  display: inline-block;
  content: '##';
  text-shadow: 0 0 20px currentcolor;
  filter: blur(10px);
}
.price.shown span.dollars:after,
.price.shown span.dollars-fees:after,
.price.shown span.cents:after,
.price.shown span.cents-fees:after,
.price.shown span.dollars:after,
.price.shown span.cents:after {
  display: none;
  filter: blur(0);
}

/* -- for dynamic LGAs -- */
.lga-item {
  display: none;
  opacity: 0;
  &.lga-replaced {
    display: inline;
    opacity: 1;
  }
}

/* -- Customize Bootstrap Utilities-- */
.w-auto {
  max-width: 100% !important;
}

$gridBreakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$fontWeights: 300, 400, 500, 600, 700, 800;
@each $weight in $fontWeights {
  .fw-#{$weight} {
    font-weight: $weight !important;
  }
}

$widths: 5, 10, 20, 25, 30, 40, 50, 60, 75, 80, 90, 100;
@each $width in $widths {
  .w-#{$width} {
    width: $width * 1% !important;
  }
}
@each $class, $size in $gridBreakpoints {
  @media (min-width: $size) {
    .w-#{$class}-auto {
      width: 100% !important;
    }
    @each $width in $widths {
      .w-#{$class}-#{$width} {
        width: $width * 1% !important;
      }
    }
  }
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}

// Line spacing modifiers
.ls-10 {
  letter-spacing: 0.1rem;
}
.ls-15 {
  letter-spacing: 0.15rem;
}
.ls-20 {
  letter-spacing: 0.2rem;
}
.ls-25 {
  letter-spacing: 0.25rem;
}

// Line height modifiers
.lh-1 {
  line-height: 1;
}
.lh-12 {
  line-height: 1.2;
}
.lh-14 {
  line-height: 1.4;
}
.lh-16 {
  line-height: 1.6;
}
.lh-18 {
  line-height: 1.8;
}
.lh-20 {
  line-height: 2;
}
.lh-25 {
  line-height: 2.5;
}
