/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #777;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #777;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    margin: 0 10px 30px 0;
  }
  img.bbb {
    margin: 0 0 30px 10px;
  }
}

/* --------- asc styles --------- */
#header-wrap {
  background: $theme-primary;
  border-bottom: 1px solid #ccc;
}

#header {
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 13px;
    border: 0;
    width: 75%;
    max-width: 450px;
    img {
      margin-right: 15px;
      vertical-align: middle;
      width: auto;
      max-width: 175px;
    }
    span {
      display: block;
      margin-top: 10px;
      font-size: 18px;
      line-height: 1.2;
      vertical-align: middle;
      font-family: 'Open Sans', Verdana;
      font-weight: 600;
      color: #fff;
    }
  }
  #support {
    p {
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      padding: 0 12px 2px 12px;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}
.card {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  min-height: 320px;
}
.card-header {
  background: #e6e9ed;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.card-body {
  background: #f5f7fa;
  color: #666;
  border-radius: 5px;
  .member-form {
    max-width: 400px;
  }
  .status {
    i {
      color: #fff;
      border-radius: 50px;
      padding: 3px;
      margin: 0 5px 0 2px;
      &.icon-close {
        background: #da4453;
      }
      &.icon-check {
        background: #00d516;
      }
    }
    .valid {
      padding: 5px 10px;
      background: #f4fff2;
      border: 1px solid #e6e9ed;
      border-radius: 3px;
      .text {
        font-size: 1.2em;
        font-weight: 600;
      }
    }
    .invalid {
      padding: 5px 10px;
      background: #ffe5e7;
      border: 1px solid #e6e9ed;
      border-radius: 3px;
      .text {
        font-size: 1.2em;
        font-weight: 600;
      }
    }
    .invalid-desc {
      font-size: 0.95em;
      margin-top: 10px;
      color: #da4453;
      line-height: 1.2em;
    }
  }
  .section {
    p {
      margin-right: 10px;
      background: #fff;
      border: 1px solid #e6e9ed;
      border-radius: 3px;
      padding: 5px 10px;
      display: inline-block;
    }
  }
}
.alert {
  padding: 10px 20px;
  margin-bottom: 20px;
  border: 1px solid #ffeeba;
  border-radius: 3px;
  color: #856404;
  background-color: #fff3cd;
  p {
    margin-bottom: 0;
  }
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  // this adds an extra padding to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      max-width: 175px;
      margin-right: 15px;
    }
    span.big {
      font-size: 1.9em;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  // this reverts the extra padding added to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 18px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Extra extra large devices (extra large desktops, 1400px and up)
@media (min-width: 1400px) {
}
